<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Applications</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Applications' }"
              >Applications</router-link
            >
          </li>
          <li class="breadcrumb-item active">Fees</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Payment</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody v-if="userrequest.data">
                  <tr
                    v-for="(value, item) in userrequest.data.items"
                    :key="item"
                  >
                    <td>{{ item }}</td>
                    <td>{{ value }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left" colspan="2">
                      <strong>Total:</strong> {{ userrequest.data.total }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-group">
              <button
                @click.prevent="showRefNum"
                name="submit"
                type="submit"
                class="btn btn-primary mr-2"
                value="Submit"
                onclick="this.blur();"
              >
                Pay at the school
              </button>
              <on-line-payment
                v-if="userrequest.data"
                :userrequest="userrequest"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnLinePayment from "@/components/OnLinePayment.vue";
import useApplication from "@/composables/applications";
import {
  onBeforeMount,
  inject,
  onDeactivated,
  onUnmounted,
  onMounted,
} from "vue";
import { useRouter } from "vue-router";

export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  components: {
    OnLinePayment,
  },
  setup(props) {
    const swal = inject("$swal");
    const router = useRouter();

    const {
      getApplicationFees,
      userrequest,
      checkBankMisrPaymentStatus,
      // msgs,
      // errors,
    } = useApplication();

    onBeforeMount(async () => {
      await getApplicationFees(props.id);
    });

    onMounted(() => {
      // Add an event listener
      document.addEventListener("completePaymentCallback", async function () {
        await checkBankMisrPaymentStatus(
          userrequest.value.merchant_ref_num
        ).then((resp) => {
          if (resp.success == true) {
            swal
              .fire({
                icon: "success",
                title: "Completed...",
                text: resp.data,
              })
              .then(() => {
                router.push({ name: "Dashboard-Home" });
              });
          } else {
            swal.fire({
              icon: "error",
              title: "Oops...",
              text: resp.message,
            });
          }
        });
      });
    });

    onDeactivated(() => {
      document.removeEventListener("completePaymentCallback", function () {
      });
    });

    onUnmounted(() => {
      document.removeEventListener("completePaymentCallback", function () {
      });
    });

    const showRefNum = () => {
      swal.fire({
        showCloseButton: true,
        closeButtonText: "Close",
        text:
          "Please provide this number " +
          userrequest.value.system_ref_num +
          " to the student affairs employee",
        allowOutsideClick: false,
      });
    };

    return {
      userrequest,
      showRefNum,
      checkBankMisrPaymentStatus,
    };
  },
};
</script>