<template>
  <button
    name="submit"
    :disabled="disableBtn"
    type="submit"
    class="btn btn-primary"
    value="Submit"
    @click="pay"
    onclick="this.blur();"
  >
    {{ btnPayNowTxt }}
  </button>
</template>

<script>
import { onBeforeMount, ref } from "vue";

export default {
  props: ["userrequest"],
  name: "OnLinePayment",
  setup(props) {
    const disableBtn = ref(false);
    const btnPayNowTxt = ref("Pay Now");

    onBeforeMount(() => {
      window.Checkout.configure({
        merchant: "CBIS_EGP",
        order: {
          amount: Math.ceil(props.userrequest.total_amount * 1.0147134),
          currency: "EGP",
          description: "Nothing to say",
          id: props.userrequest.merchant_ref_num,
        },
        interaction: {
          operation: "PURCHASE", // set this field to 'PURCHASE' for Hosted Checkout to perform a Pay Operation.
          merchant: {
            name: "Citadel British International School",
          },
        },
        session: {
          id: props.userrequest.payment_session_id,
        },
      });
    });

    const pay = () => {
      disableBtn.value = true;
      btnPayNowTxt.value = "Please Wait...";
      window.Checkout.showLightbox();
    };

    return {
      pay,
      disableBtn,
      btnPayNowTxt,
    };
  },
};
</script>